main {
  display: flex;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 1.5rem;
  font-family: 'Roboto', sans-serif;
  width: 70%;
  max-width: 700px;
  backdrop-filter: blur(4px);
  border-radius: 0.5rem;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 1rem;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

@media only screen and (max-device-width: 580px) {

  main {
    width: 90%;
  }

  .questions-insights-by-result {
    flex-direction: column !important;
  }

  .questions-insights-by-result .correct-users, .questions-insights-by-result .incorrect-users {
    margin-top: 1rem !important;
    align-items: start !important;
  }

  .questions-insights-by-result {
    margin-top: 0rem !important;
  }

  .questions-insights-by-result .answered-heading {
    margin-left: 0 !important;
  }
}
.questions-insights-correct {
  margin-top: 1rem;
  color: green;
}
.questions-insights-incorrect {
  margin-top: 1rem;
  color: red;
}
.react-quiz-container, .results, .manage-users-container,
.manage-questions-container, .insights-container, .leaderboard-container {
  width: 100%;
}
.manage-users-container {
  margin-bottom: 1rem;
}

.top-five-list .ranking {
  padding-left: 1.5rem;
  padding-right: 2rem;
}

.top-five-list .medal {
  font-size: 25px;
}
.top-five-list .no-medal {
  padding-left: 2.1rem;
}

.questions-insights-by-result {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  
}
.questions-insights-by-result .answered-heading {
  margin: 0.5rem;
  text-align: center;
}
.questions-insights-by-result .correct-users, .questions-insights-by-result .incorrect-users {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add-question-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.possible-answer{
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.answer-label {
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
.correct-answer-switch {
  display: flex;
  margin: 0 !important;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.active-question-switch {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0rem 1rem 0rem !important;
  flex-grow: 1;
}

.save-question-btn {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loading-users-table, .loading-questions-view {
  display: flex;
  justify-content: center;
}
.add-user-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-top: 2rem;
}
.add-user-switches {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.add-user-btn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}
.add-user-form {
  width: 50%;
}
.bottom-navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
h1, h2, h3, h4 {
  margin-bottom: 1rem !important;
}

.no-user-selected {
  display: flex;
  flex-direction: column;
  margin: 2rem;
}

.no-user-selected .homeBtn {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.react-quiz-container .startQuizWrapper {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 3rem;
}
.alert-dialog-actions {
  display: flex !important;
  justify-content: space-around !important;
}
.admin-buttons .btn{
  margin: 0.5rem;
}
.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.login-container .login-buttons {
  align-items: center;
  justify-content: center;
  display: flex;
}

.login-container .login-description {
  text-align: center;
  margin-bottom: 1rem;
}
.quiz-title {
  text-align: center;
}

.insights-container .green {
  color: green;
}
.insights-container .red {
  color: red;
}
.insights-container .yellow {
  color: rgb(179, 179, 46);
}
.react-quiz-container .questionWrapper .btn {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  font-size: 16px;
  display: block;
  white-space: normal;
  text-align: unset;
}

.react-quiz-container .questionWrapper .btn.correct {
  background: green;
  color: white;
}

.react-quiz-container .questionModal .alert {
  padding: 20px;
  margin-bottom: 21px;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #fff;
}

.react-quiz-container .correct {
  background: green;
}

.card-body{
  padding: 0.5rem;
}

.react-quiz-container .incorrect, .react-quiz-container .incorrect:hover {
  background: red;
  color: white !important;
}

.react-quiz-container .questionWrapper img {
  width: 92%;
  margin-left: 4%;
  margin-right: 4%;
}

.react-quiz-container .answerBtn {
  width: 100%;
}

.react-quiz-container .selected {
  background: #007bff;
  color: white;
}

.category-list-box {
  margin-top: 0.5rem;
}
.react-quiz-container .startQuizBtn, .react-quiz-container .loginBtn {
  width: 10rem;
  height: 3.5rem;
  margin-left: 1rem;
  font-size: 16px;
}

.login-container .loginBtn {
  margin: 0.5rem;
}

.react-quiz-container .result-answer-wrapper {
  margin-bottom: 20px;
}

.react-quiz-container .result-answer-wrapper .explanation {
  padding: 20px;
  margin: 0px 20px 20px 20px;
  border: 1px solid #e8e8e8;
}

.react-quiz-container .result-answer-wrapper .tag-container {
  margin: 20px;
}

.tag-container .MuiTypography-body1 {
  font-weight: 500;
}

.tag-container {
  display: flex;
}

.react-quiz-container .result-answer {
  padding: 0px 20px;
}

.react-quiz-container .quiz-synopsis {
  margin: 15px 0px;
}

.react-quiz-container .quiz-result-filter {
   width: 8rem;
   font-size: 16px;
}

.quiz-result-buttons {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.react-quiz-container .tag-container {
  margin-top: 20px;
  margin-bottom: 20px;
}

.react-quiz-container .selection-tag,
.react-quiz-container .number-of-selection{
  padding: 7px;
  border-radius: 5px;
  color: #ffffff;
}

.react-quiz-container .number-of-selection {
  background: #1998d2;
  margin-left: 5px;
}

.react-quiz-container .selection-tag.single {
  background: #1976d2;
}

.react-quiz-container .selection-tag.multiple {
  background: #1976d2;
}
.react-quiz-container .selection-tag.segment {
  background: #3db4b9;
  margin-left: 5px;
}

.react-quiz-container .questionBtnContainer{
  display:flex;
  justify-content: space-between;
}

.user-responses-card .user-qa, .user-responses-card {
  margin-top: 1rem;
}

.no-quiz-result-found {
  margin-top: 1rem;
  text-align: center;
}
.user-responses-card .card-footer {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.user-responses-card .question{
  font-weight: 500;
}

.user-responses-card .user-name {
  font-weight: 600;
}

.user-responses-card .correct, .question-card .correct {
  color: green;
}
.user-responses-card .incorrect {
  color: red;
}

.question-card, .user-card {
  margin-bottom: 1rem;
}
.question-explanation {
  margin-top: 1rem;
}
.card-actions {
  padding: 0 1rem 1rem 1rem !important;
}
.question-modal-btn, .category-modal-btn  {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-modal-btn button, .category-modal-btn button {
  flex-grow: 1;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}

.category-input {
  margin-bottom: 1rem !important;
}

.category-list-box *:not(svg) {
  font-size: 16px !important;
}
.category-list-box {
  width: 49%;
  margin-top: 1rem;
}
.question-picture-dropdown {
  width: 49%;
  margin-top: 1rem !important;
}
.categories-and-dropdown {
  display: flex;
  justify-content: space-between;
}