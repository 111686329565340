#root {
  display: flex;
  min-height: 86vh;
  justify-content: center;
  align-items: center;
}

body {
  background-image: url("../public/seamless-pattern-with-doodle-glasses.jpg");
  background-size: 20rem;
}

.login {
  margin-top: 35vh;
  margin-bottom: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center
}

.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.not-found-image {
  max-width: 70%;
  margin-bottom: 1rem;
}

.username, .display-user-dropdown {
  width: 10rem !important;
  font-size: 16px;
}
.results-table {
  margin-top: 1rem;
}
.results-table .loading-results-table {
  display: flex;
  justify-content: center;
}
.select-result {
  display: flex;
  justify-content: center;
}
.dropdown-container {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
}
.MuiButtonBase-root {
  text-transform: unset !important;
}

.MuiTypography-h1 {
  font-size: 3rem !important;
}
.MuiTypography-h2 {
  font-size: 2.5rem !important;
}
.MuiTypography-h3 {
  font-size: 2rem !important;
}
.MuiTypography-h4 {
  font-size: 1.5rem !important;
}
.MuiTypography-h5 {
  font-size: 1rem !important;
}

.login-container .login-heading{
  text-align: center;
}